import React from "react";
import {User} from "../../../../generated-types";
import Text from "antd/es/typography/Text";
import {Container, CustomAvatar} from "./CustomUserAvatar.styled";
import {useNavigate} from "react-router-dom";
import {theme} from "antd";

interface CustomUserAvatarProps {
    user: User;
}

export const CustomUserAvatar: React.FC<CustomUserAvatarProps> = ({user}) => {
    const navigate = useNavigate();

    const {
        token: {colorWhite},
    } = theme.useToken();

    return <Container onClick={() => navigate('/profile')}>
        <CustomAvatar user={user as User} style={{
            width:  '40px',
            height:  '40px'
        }
        }/>
        <Text style={{color: colorWhite}}>{user.username}</Text>
    </Container>
}