
import React, {type SVGProps} from "react"
import {theme} from "antd";

export function IconTeam(props: SVGProps<SVGSVGElement>) {
   
    return (
<svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" fill-opacity="1" stroke-opacity="1" {...props} stroke="currentColor">
    <g id="name=team, size=24" clip-path="url(#clip0_30_5560)">
        <g id="Frame 3">
            <path id="Vector" d="M7 18V17C7 14.2386 9.23858 12 12 12C14.7614 12 17 14.2386 17 17V18" stroke="currentColor"
                  stroke-linecap="round" stroke-linejoin="round"/>
            <path id="Vector_2" d="M1 18V17C1 15.3431 2.34315 14 4 14" stroke="currentColor" stroke-linecap="round"
                  stroke-linejoin="round"/>
            <path id="Vector_3" d="M23 18V17C23 15.3431 21.6569 14 20 14" stroke="currentColor" stroke-linecap="round"
                  stroke-linejoin="round"/>
            <path id="Vector_4"
                  d="M12 12C13.6569 12 15 10.6569 15 9C15 7.34315 13.6569 6 12 6C10.3431 6 9 7.34315 9 9C9 10.6569 10.3431 12 12 12Z"
                  stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"/>
            <path id="Vector_5"
                  d="M4 14C5.10457 14 6 13.1046 6 12C6 10.8954 5.10457 10 4 10C2.89543 10 2 10.8954 2 12C2 13.1046 2.89543 14 4 14Z"
                  stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"/>
            <path id="Vector_6"
                  d="M20 14C21.1046 14 22 13.1046 22 12C22 10.8954 21.1046 10 20 10C18.8954 10 18 10.8954 18 12C18 13.1046 18.8954 14 20 14Z"
                  stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"/>
        </g>
    </g>
    <defs>
        <clipPath id="clip0_30_5560">
            <rect width="24" height="24" fill="white"/>
        </clipPath>
    </defs>
</svg>

    )
}
