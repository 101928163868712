import React, {useState} from "react";
import {Button, Input, Spin, theme} from "antd";
import {EnterOutlined} from "@ant-design/icons";
import {useTranslation} from "react-i18next";
import { IconSend } from "./Icon/IconSend";

interface AddCommentInputProps {
    placeholder?: string,
    onCompleted?: (event: Event) => void,
    onRun: (text: string, callback: () => void) => void,
    isBlocked?: boolean | undefined
}

const AddCommentInput = ({onRun, placeholder, onCompleted, isBlocked = false}: AddCommentInputProps) => {
    const {t} = useTranslation();
    const [focused, setFocused] = useState(false);
    const {token}=theme.useToken()
    const [inProgress, setInProgress] = useState(false);
    const [text, setText] = useState("");

    const AddMessage = (fastAdd: boolean) => {
        if (inProgress)
            return

        if (!text || !text.trim())
            return

        if (fastAdd) {
            setInProgress(true)
            onRun(text, () => {
                setFocused(false);
                setInProgress(false)
                setText("");
            })
        } else {
            setText("")
        }
    }

    return <div>
        <Spin spinning={inProgress}>
            <Input disabled={isBlocked} style={{width: '100%', height: 40, fontSize: 12}}
                   placeholder={placeholder ?? t('events.addComment')}
                   variant={"outlined"}
                   onChange={(e) => {
                       if (inProgress) {
                           e.preventDefault()
                           e.stopPropagation()
                           return;
                       }
                       setText(e.target.value as any)
                   }}
                   onKeyPress={(e) => {
                       if (e.charCode !== 13)
                           return;
                       AddMessage(!e.ctrlKey)
                   }}
                   size="middle"
                   suffix={focused && <Button type={"text"} style={{
                    width: 24, 
                    height: 24, 
                    backgroundColor: token.colors.ui.accent,
                    color: token.colors.font.white,
                    lineHeight: 1
                    
                    }} 
                    styles={{icon: {height: 16}}}
                onClick={(e) => {
                       AddMessage(!e.ctrlKey)
                   }}
                   icon={<IconSend style={{alignSelf: 'center', fontSize: 16}}/>}
                   />}
                   onFocus={() => setFocused(true)}
                   onBlur={() => setFocused(false)}
                   value={text}
                   styles={{suffix: {width: 24},}}
            />
        </Spin>
    </div>
}

export default AddCommentInput