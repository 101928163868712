
import React, {type SVGProps} from "react"
import {theme} from "antd";

export function IconClose(props: SVGProps<SVGSVGElement>) {
   
    return (
<svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" fill-opacity="1" stroke-opacity="1" {...props} stroke="currentColor">
    <g id="name=close, size=24">
        <g id="Frame 19" clip-path="url(#clip0_192_12486)">
            <path id="Vector"
                  d="M5.75886 5.75921L12 12.0003M12 12.0003L18.2411 18.2415M12 12.0003L18.2411 5.75921M12 12.0003L5.75886 18.2415"
                  stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"/>
        </g>
    </g>
    <defs>
        <clipPath id="clip0_192_12486">
            <rect width="24" height="24" fill="white"/>
        </clipPath>
    </defs>
</svg>

    )
}
