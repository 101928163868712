
import React, {type SVGProps} from "react"
import {theme} from "antd";

export function IconCommentShow(props: SVGProps<SVGSVGElement>) {
   
    return (
<svg width="1em" height="1em" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" fill-opacity="1" stroke-opacity="1" {...props} stroke="currentColor">
    <g id="name=comment show, size=16" height="1em">
        <g id="Group 51">
            <g id="Group 50">
                <path id="Vector 11"
                      d="M3.38477 12.8933V14.8776C3.38477 15.2876 3.85106 15.5233 4.18121 15.2803L7.42323 12.8933"
                      stroke="currentColor"/>
                <path id="Vector 12"
                      d="M3.38462 12.8933C3.38462 12.8933 3.025 12.8933 2.50051 12.8933C1.39595 12.8933 0.5 11.9979 0.5 10.8933V3C0.5 1.89543 1.39543 1 2.5 1L15.5 1M15.5 12.8933H7.42308"
                      stroke="currentColor" stroke-linecap="round"/>
            </g>
            <path id="Vector" d="M15.5 4L12.5 7L15.5 10" stroke="currentColor" stroke-linecap="round"
                  stroke-linejoin="round"/>
        </g>
    </g>
</svg>

    )
}
