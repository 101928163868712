
import React, {type SVGProps} from "react"
import {theme} from "antd";

export function IconMenu(props: SVGProps<SVGSVGElement>) {
   
    return (
<svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" fill-opacity="1" stroke-opacity="1" {...props} stroke="currentColor">
    <g id="name=menu, size=24">
        <g id="Group 49">
            <path id="Vector" d="M3.92383 6H20.0762" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"/>
            <path id="Vector_2" d="M3.92383 12H20.0762" stroke="currentColor" stroke-linecap="round"
                  stroke-linejoin="round"/>
            <path id="Vector_3" d="M3.92383 18H20.0762" stroke="currentColor" stroke-linecap="round"
                  stroke-linejoin="round"/>
        </g>
    </g>
</svg>

    )
}
