import React, {useState} from 'react';
import {Button, Col, Form, FormInstance, Input, notification, Row} from 'antd';
import {useTranslation} from "react-i18next";
import {
    useCheckUsersUnionNameLazyQuery,
    User,
    UsersUnion,
    useUserPaymentAccountQuery,
    useUsersUnionDeleteMutation
} from "../../generated-types";
import UserAvatar from "../User/UserAvatar";
import Text from "antd/es/typography/Text";
import {Link, useNavigate} from "react-router-dom";
import TooltipButton from "../TooltipButton";
import AvatarEditor from "../User/AvatarEditor";

interface UsersUnionSettingsProps {
    union: UsersUnion,
    save: any,
    form: FormInstance,
    saveButton?: boolean
    deleteButton?: boolean
}

const UsersUnionSettings = (props: UsersUnionSettingsProps) => {
    const {t} = useTranslation();
    const [isActiveSave, setActiveSave] = useState<boolean>(false); //Делает кнопку аутивной при изменении полей
    // эта переменная нужна, чтобы побороть проблему, когда кастомный валидатор поля name не отрабатывает на собранном приложении и не работает сохранение команды
    const [validatedName, setValidatedName] = useState<string>();
    const navigate = useNavigate();

    const isNew = !props.union?.id;

    const {data: {paymentAccount} = {payment: null}} = useUserPaymentAccountQuery({
        variables: {id: isNew ? null : props.union.paymentAccount.id}
    });

    const [checkUsersUnionName] = useCheckUsersUnionNameLazyQuery({})


    const [deleteUsersUnion, { loading}] = useUsersUnionDeleteMutation({
        onCompleted: (res) => {
            if (res.usersUnionDelete) {
                notification.success({
                    message: t("usersUnion.deletedSuccessfully"),
                    description: props.union.title,
                });
                navigate("/")
            }
        }
    });

    const onChangeHandler = (): void => {
        setActiveSave(true);
    }

    const onSaveHandler = (): void => {
        setActiveSave(false);
        props.save(props.union.id, props.form.getFieldsValue())
    }

    const onDeleteHandler = (): void => {
        deleteUsersUnion({
            variables: {
                usersUnionId: props.union.id
            }
        })
    }

    return (
        <>
            <Form layout="vertical" requiredMark={false} form={props.form}
                  initialValues={props.union as any}
                  onFinish={props.save}
                  onChange={onChangeHandler}
            >
                <Row gutter={16}>
                    <Col span={12}>
                        <AvatarEditor contextType={props.union.__typename} contextId={props.union.id}
                                      avatarUrl={props.union.avatarUrl}/>
                        <Form.Item
                            name="title"
                            label={t('usersUnion.title')}
                            rules={[{required: true, message: "" + t('usersUnion.titlePlaceholder')},
                                    {required: true, message: t('usersUnion.oneHundredMax') as string, max: 100},
                            ]}
                        >
                            <Input placeholder={"" + t('usersUnion.titlePlaceholder')}/>
                        </Form.Item>
                    </Col>
                    {!isNew && paymentAccount &&
                        <Col>
                            <Link to={`/User/${paymentAccount?.id}`}>
                            <Text style={{display: "block", marginBottom: 10}}>{t('usersUnion.paymentAccount')}</Text>
	                            <UserAvatar key={paymentAccount?.id} user={paymentAccount.managers[0] as User}/>
	                            <Text style={{marginLeft: 8}}>{paymentAccount?.title}</Text>
                            </Link>
                        </Col>
                    }
                </Row>
                <Row gutter={16}>
                    <Col span={12}><>

                        <Form.Item
                            // hasFeedback
                            name="name"
                            label={t('usersUnion.name')}
                            // validateDebounce={500}
                            rules={
                            [
                                {required: true, message: t('usersUnion.namePlaceholder') as string},
                                {required: true, message: t('usersUnion.onlyNumbersAndSymbols') as string,
                                    type: "string",
                                    min: 6,
                                    pattern: /^[a-z][a-z0-9]*$/},
                                {required: true, message: t('usersUnion.oneHundredMax') as string, max: 100},
                                { validator: (obj, val) => {
                                    if (val === validatedName) return Promise.resolve();

                                    return new Promise<any | void>((resolve, reject) => {
                                        checkUsersUnionName({
                                            variables: {
                                                id: props.union.id,
                                                name: val
                                            },
                                            onCompleted: (data) => {
                                                if (data && data.result) {
                                                    resolve(undefined)
                                                    setValidatedName(val)
                                                }
                                                else
                                                    reject(t('usersUnion.nameIsBusySelectAnother') as string)
                                            }
                                        })
                                    })
                                    }
                                }
                            ]
                        }
                        >
                            <Input placeholder={"" + t('usersUnion.namePlaceholder')}/>
                        </Form.Item>
                    </></Col>
                </Row>
                <Row gutter={16}>
                    <Col span={12}>
                        <Form.Item
                            name="description"
                            label={"" + t('usersUnion.description')}
                            rules={[
                                {
                                    required: false, message: t('usersUnion.oneHundredMax') as string, max: 100
                                },
                            ]}
                        >
                            <Input.TextArea rows={4} placeholder={"" + t('usersUnion.descriptionPlaceholder')}/>
                        </Form.Item>
                    </Col>
                </Row>
                <Row>
                {props.saveButton &&
                        <Button disabled={!isActiveSave} onClick={onSaveHandler}>{t('profile.save')}</Button>
                }
                {props.deleteButton &&
			            <TooltipButton loading={loading}
			                           danger={true}
			                           type={"text"}
                                       tooltipProps={{
                                           title: t('usersUnion.deletedConfirmation')
                                       }}
			                           popconfirmProps={{
                                     title: t('beCaution'),
                                     color: "red",
                                     description: t('usersUnion.deletedConfirmation'),
                                     onConfirm: onDeleteHandler,
                                     okText: t('usersUnion.delete'),
                                     okButtonProps: {
                                         danger: true
                                     }
                                 }}
			            >
                      {t('usersUnion.delete')}
			            </TooltipButton>
                }
                </Row>
            </Form>
        </>
    );
};

export default UsersUnionSettings;
