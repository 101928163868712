import React, { CSSProperties, useRef, useState } from 'react';
import { Alert, Button, Col, Drawer, Form, Grid, Row } from 'antd';
import { useTranslation } from "react-i18next";
import {
    ExternalFile,
    Task,
    TaskMember,
    TaskSaveMutation,
    useTaskQuery,
    useTaskSaveMutation
} from "../../generated-types";
import TaskCard, { prepareTask, valuesToTaskInput } from "./TaskCard";
import { makeVar, ReactiveVar, useReactiveVar } from "@apollo/client";
import Spinner from "../Spinner";
import ProjectBreadcrumb from "../ProjectBreadcrumb";
import TaskResolved, { ResolvedColor } from "./TaskResolved";
import TaskRemoveBtn from "./TaskRemoveBtn";
import TaskEvents from "./TaskEvents";
import styled, { css } from 'styled-components';
import useToken from 'antd/es/theme/useToken';
import TooltipButton from '../TooltipButton';
import { IconCommentHide } from '../Icon/IconCommentHide';
import { IconCommentShow } from '../Icon/IconCommentShow';


const DrawerBody = styled.div<{ isMobile: boolean }>`
    display: flex;
    overflow-y: auto;
    height: 100%;
    width: 100%;
    position: relative;
    padding: ${({ isMobile }) => isMobile ? '0px 16px' : '0'};
    box-sizing: border-box;
    overflow-x: hidden;
`

const widthTransition = css`
transition: max-width, width .1s ease-in-out;
`


const LeftPanel = styled.div<{ historyOpen: boolean, isMobile: boolean }>`

position: relative;
width: ${({ historyOpen, isMobile }) => isMobile ? '100%' : (historyOpen ? '50%' : '100%')};
height: ${({ isMobile }) => isMobile ? 'auto' : '100%'};
overflow-x: visible;
${widthTransition};

`


const RightPanel = styled.div<{ historyOpen: boolean, isMobile: boolean }>`
    
    max-height: 100%;
    height: ${({ isMobile }) => isMobile ? 'calc(100% - 80px)' : '100%'};
    max-width: ${({ historyOpen, isMobile }) => isMobile ? '100%' : (historyOpen ? '50%' : '0%')};
    width:  ${({ historyOpen, isMobile }) => isMobile ? '100%' : (historyOpen ? '50%' : '0%')};
    ${widthTransition};
  
`
interface TaskEditProps {
    // taskId: Task
}

const TaskEditorModal: React.FC<TaskEditProps> = () => {
    const { t } = useTranslation();
    // const navigate = useNavigate();
    const [open, setOpen] = useState(true);
    const [form] = Form.useForm();
    // const [task, setTask] = useState<Task>({title: "", description: ""} as Task);
    const ctx = useReactiveVar(editTaskContext);
    // const allP = useReactiveVar(allProjects);
    const [historyOpen, setHistoryOpen] = useState(false)
    const [, token] = useToken()
    const rightBlockRef = useRef<HTMLDivElement>(null)
    const rightBlockContentRef = useRef<HTMLDivElement>(null)
    const drawerBodyContentRef = useRef<HTMLDivElement>(null)
    const screen = Grid.useBreakpoint()

    const taskId = ctx.taskId;



    // TODO: тут не надо пустую строку передавать. как лучше поступить, чтобы избавиться от этого?
    const { data, error, loading } = useTaskQuery({
        variables: { id: taskId ?? "" },
        onCompleted: (data) => {
            const preparedTask = prepareTask(data.task as Task);
            form.setFieldsValue(preparedTask);
        }
    });
    const isBlocked = data?.task.project.paymentAccount?.isBlocked;
    const isArchived = data?.task.project.archived;

    const [taskSave] = useTaskSaveMutation({
        optimisticResponse: ({ id, task: ttt }) => {
            const members = ttt.members.map(m => ({
                id: m.userId,
                memberType: m.memberType,
                user: project.members.find(pm => pm.user.id == m.userId)?.user
            } as any));

            // Почему-то не пересчитывается счетчик файлов на карточке на доске.
            const files = ttt.files
            const { title, tags, status, endTime, endDate, startTime, startDate, description, importance } = ttt;
            const res = {
                taskSave: {
                    ...task, title, tags, status, endTime, endDate,
                    startTime, startDate, description, importance, members, files
                }
            } as TaskSaveMutation;
            return res;
        },
        // TODO: эта штука не работает для моих задач. Из-за этого пришлось впендюрить pollInterval в TaskViewList
        // refetchQueries: [RefetchesList.TasksByViewId],
    });

    if (!taskId)
        return null;

    /*if (!open)
        setOpen(true)*/

    if (!data)
        return <Spinner />

    const task = data.task as Task;
    const { project } = task;

    const onClose = () => {
        form.submit();//Валидируем поля формы
        console.log('valid')
        setOpen(false);
        console.log('closed')
        //TODO: Придумать что-то другое по этой теме, попап должен плавно закрываться, но если мы ставим undefined, то он просто резко исчезает, 
        //пока я поставил задержку, чтопы попап успел закрыться
        setTimeout(() => editTaskContext({ taskId: undefined }), 1000)


    };
    const save = (values: any, members: TaskMember[], files: ExternalFile[], tags: string[]) => {
        const variables = {
            id: taskId,
            task: valuesToTaskInput(values, members, files, tags)
        };
        variables.task.status = task.status
        variables.task.projectId = task.project.id

        taskSave({ variables })
    };

    const historyButtonStyle: CSSProperties = {
        border: 0,
        borderRadius: 4,
        width: 24,
        height: 24,
        fontSize: 16,
        backgroundColor: token.colors.ui.bgLight,
        position: 'absolute',
        boxShadow: 'none',
        top: '50%',
        right: 0,
        transform: historyOpen ? 'translate(50%,-50%)' : 'translate(-4px,-50%)',
        color: token.colors.font.primary,
        transition: 'transform .3s ease-in-out'
    }

    const drawerWidth = () => {
        if (screen.md)
            return historyOpen ? 1131 : 564
        if (screen.sm)
            return historyOpen ? '100svw' : '50svw'
        if (screen.xs)
            return '100%'

    }
    //Закрытие блока истории без его сжатия 
    const historyButtonClickHandler = () => {
        if (rightBlockContentRef.current && historyOpen) {
            const width = rightBlockContentRef.current.clientWidth
            rightBlockContentRef.current.style.width = width + 'px'

            window.requestAnimationFrame(()=>setHistoryOpen(false))
            
        }

        if (rightBlockContentRef.current && drawerBodyContentRef.current && !historyOpen) {
            const rightBlockContent = rightBlockContentRef.current
            const width = drawerBodyContentRef.current.clientWidth
            rightBlockContent.style.width = width + 'px'



            const onTransitonEnd = () => {
                if (rightBlockRef.current) {

                    rightBlockContent.style.width = '100%'
                    rightBlockRef.current?.removeEventListener("transitionend", onTransitonEnd)

                }
            }

            rightBlockRef.current?.addEventListener("transitionend", onTransitonEnd)

            setHistoryOpen(true)
        }
    }

    return (
            <Drawer key="2"
                title={<>
                    <Row>
                        <Col flex={"auto"}>
                            <span>{t('task.editWindowTitle')}</span>
                            <span className={"taskNumber"} style={{}}>#{task.number}</span>
                        </Col>
                        <Col flex={"40px"}>{task?.id && <TaskResolved
                            disabled={isBlocked || isArchived}
                            id={task.id}
                            value={task.resolved} />}</Col>
                        <Col flex={"60px"}><TaskRemoveBtn disabled={isBlocked || isArchived} id={task?.id} onChange={(value) => {
                            if (value)
                                onClose()
                        }} /></Col>
                    </Row>
                    <ProjectBreadcrumb projectId={project.id} />
                    {/*<TaskChangeColumn taskView={ta} task={task} />*/}
                </>}
                width={drawerWidth()}
                onClose={onClose}
                open={open}
                styles={{
                    body: { padding: 0 },
                    header: { backgroundColor: task.resolved ? ResolvedColor : undefined }
                }
                }
            // footer={
            //     <Space direction="vertical" style={{ width: '100%' }}>
            //         <Button disabled={isBlocked ||isArchived} type="primary" block onClick={form.submit} htmlType="submit">
            //             {t('task.save')}
            //         </Button>
            //     </Space>
            // }
            >
                <Spinner loading={loading} />
                {error && <Alert type="error" message={error.message} banner />}
                {!loading && !error && <DrawerBody ref={drawerBodyContentRef}
                    style={{ flexDirection: screen.xs ? 'column' : 'row' }}
                    isMobile={screen.xs!}>
                    <LeftPanel historyOpen={historyOpen} isMobile={screen.xs!}>
                        <div style={{ height: '100%', overflowY: 'auto', overflowX: 'hidden' }}>
                            <TaskCard projectId={project.id}
                                task={task} save={save}
                                project={project}
                                form={form}
                            />
                        </div>
                        {!screen.xs && <TooltipButton style={historyButtonStyle}
                            onClick={historyButtonClickHandler}
                            icon={historyOpen ? <IconCommentHide /> : <IconCommentShow />}
                            styles={{icon: {opacity: 0.5}}}
                            tooltipProps={{ title: historyOpen ? t('events.hideHistory') : t('events.showHistory'), placement: "leftTop" }} />}
                    </LeftPanel>
                    <RightPanel style={{
                        backgroundColor: token.colors.ui.bgLight2,
                        borderRadius: screen.xs ? 16 : 0
                    }}
                        historyOpen={historyOpen}
                        isMobile={screen.xs!}
                        ref={rightBlockRef}
                    >         
                            <div style={{ height: '100%', transition: 'width .3s ease-in-out' }} ref={rightBlockContentRef}>
                                <TaskEvents key={task.id} taskId={task.id} />
                            </div>     
                    </RightPanel>
                </DrawerBody>}
            </Drawer>
    );
};


// обертка, чтобы не делать лишний запрос на сервер с пустым идентификатором задачи
const TaskEditor: React.FC<TaskEditProps> = (props) => {
    const ctx = useReactiveVar(editTaskContext);
    const taskId = ctx.taskId;

    if (!taskId)
        return null;

    return <TaskEditorModal {...props} />;
};

interface EditTaskContext {
    taskId: string | undefined
}

function emptyState(): EditTaskContext {
    return {
        taskId: undefined
    }
}

export const editTaskContext: ReactiveVar<EditTaskContext> = makeVar<EditTaskContext>(emptyState());

export default TaskEditor;