import styled, {css} from "styled-components";
import type {GlobalToken} from "antd/es/theme/interface";

export const ButtonsContainer = styled.div<{ collapsed?: string, token: GlobalToken }>`

    & {
        padding-bottom: 6px;
    }

    .ant-space-gap-col-small {
    column-gap: 8px;
  }

  .ant-space-gap-row-small {
    row-gap: 8px;
  }

    .ant-space-item {
        line-height: 110%;
        font-size: 16px;
        padding: 4px 0;
        color: ${(p) => p.token.colors.font.white};

        &:hover {
            color: ${({token}) => token.colors.font.white};
            background-color: ${({token}) => token.colors.ui.accent05};
        }

        .anticon {
            width: 24px;
            height: 24px;
        }
    }


    .ant-space-item:has(.selected) {
        background-color: ${({token}) => token.colors.ui.accent05};
    }


    ${({collapsed}) =>
    collapsed &&
    css`
            display: flex;
            justify-content: center;
          `};
`

export const ButtonWrapper = styled.div<{ collapsed?: string, token: GlobalToken }>`
  display: flex;
  align-items: center;
    gap: 8px;
  cursor: pointer;
    padding: 0 14px;
  font-size: 14px;
    font-weight: 100;

 .anticon{
   width: 24px;
   height: 24px;
   
   svg{
     width: 100%;
     height: 100%;
     object-fit: cover;
   }
 }

  ${({collapsed}) =>
    collapsed &&
    css`
            justify-content: center;
          `};
`