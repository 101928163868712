import React from "react"
import TA from 'react-timeago'
// @ts-ignore
import ru from 'react-timeago/lib/language-strings/ru'
// @ts-ignore
import buildFormatter from 'react-timeago/lib/formatters/buildFormatter'
import {Tooltip} from "antd";
import {Dayjs} from "dayjs";

const formatter = buildFormatter(ru)

export interface TimeAgoProps {
    date: Dayjs;
}

const TimeAgo: React.FC<TimeAgoProps> = ({date}) => {
    return <Tooltip title={date.locale()}>
        <span style={{fontSize: "smaller", color: "grey"}}>
            <TA date={date.toDate()} formatter={formatter}/>
        </span>
    </Tooltip>
};

export default TimeAgo;