
import React, {type SVGProps} from "react"
import {theme} from "antd";

export function IconComment(props: SVGProps<SVGSVGElement>) {
   
    return (
<svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" fill-opacity="1" stroke-opacity="1" {...props} stroke="currentColor">
    <g id="name=comment, size=24">
        <path id="Vector 11" d="M6.09229 17.9692V20.69C6.09229 21.0966 6.55197 21.3332 6.8829 21.0968L11.2615 17.9692"
              stroke="currentColor"/>
        <path id="Vector 12"
              d="M6.09221 17.9692C6.09221 17.9692 5.36236 17.9692 4.40036 17.9692C3.29579 17.9692 2.3999 17.0738 2.3999 15.9692V5.19995C2.3999 4.09538 3.29533 3.19995 4.3999 3.19995H19.5999C20.7045 3.19995 21.5999 4.09538 21.5999 5.19995V15.9692C21.5999 17.0738 20.7045 17.9692 19.5999 17.9692H11.2614"
              stroke="currentColor" stroke-linecap="round"/>
        <path id="Vector 13" d="M6 12.75H18" stroke="currentColor" stroke-linecap="round"/>
        <path id="Vector 14" d="M6 9H18" stroke="currentColor" stroke-linecap="round"/>
    </g>
</svg>

    )
}
