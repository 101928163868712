
import React, {type SVGProps} from "react"
import {theme} from "antd";

export function IconArrowRight(props: SVGProps<SVGSVGElement>) {
   
    return (
<svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" fill-opacity="1" stroke-opacity="1" {...props} stroke="currentColor">
    <g id="name=arrow-right, size=24" clip-path="url(#clip0_320_20457)">
        <g id="Frame 3">
            <path id="Vector" d="M8 20L16 12L8 4" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"/>
        </g>
    </g>
    <defs>
        <clipPath id="clip0_320_20457">
            <rect width="24" height="24" fill="white"/>
        </clipPath>
    </defs>
</svg>

    )
}
