import i18next from "i18next";
import {initReactI18next} from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
// import intervalPlural from 'i18next-intervalplural-postprocessor';
import {makeVar, ReactiveVar} from "@apollo/client";
import dayjs from 'dayjs';
import 'dayjs/locale/ru';
import antEn from 'antd/locale/en_US';
import antRu from 'antd/locale/ru_RU';
import antIt from 'antd/locale/it_IT';
import {Locale} from "antd/lib/locale";

dayjs.locale('ru');


i18next
    .use(initReactI18next)
    // .use(intervalPlural)
    .use(LanguageDetector)
    .init({
        debug: true,
        fallbackLng: 'ru',
        simplifyPluralSuffix: true,
        resources: {
            // en: {
            //     translation: {
            //         auth: {
            //             signIn: "Sign In",
            //             signUp: "Sign Up",
            //             profile: "Profile",
            //             signOut: "Sign out",
            //             inputUserName: "Please input your username!",
            //             username: "Username",
            //             inputPassword: "Please input your password!",
            //             password: "Password",
            //             inputEmail: "Please input your email!",
            //             email: "Email"
            //         },
            //         submit: "Submit",
            //         noProjectsInList: "There is no projects",
            //         createNewProject: "Create",
            //         projectsList: "Projects",
            //         addUsersToProject: "Add",
            //     },
            //                     profile: {
            //                         userName: "User name",
            //                         email: "Email",
            //                         fullName: "Full name",
            //                         aboutMe: "About me",
            //                         edit: "Edit",
            //                         changePassword: "Change passowrd",
            //                         save: "Сохранить",
            //                         newPassword: "New password",
            //                         repeatPassword: "Repeat password",
            //                         shortInfo: "Short Info",
            //                     }
            // },
            // it: {
            //     translation: {
            //         auth: {
            //             signIn: "Entrata",
            //         },
            //         projectsList: "Progetti",
            //     }
            // },
            ru: {
                translation: {
                    auth: {
                        signIn: "Вход",
                        signUp: "Регистрация",
                        forgotPassword: "Забыли пароль?",
                        restorePassword: "Сохранить и войти",
                        sendRestoreLink: "Отправить ссылку",
                        sendRestoreLinkTitle: "Укажите ваш email, на который отправить ссылку для восстановления пароля",
                        restorePasswordTitle: "Укажите ваш логин и новый пароль.",
                        profile: "Профиль",
                        signOut: "Выйти",
                        inputUserName: "Пожалуйста, введите имя пользователя",
                        username: "Имя пользователя",
                        inputPassword: "Пожалуйста, введите свой пароль",
                        password: "Пароль",
                        inputEmail: "Пожалуйста, введите существующий email",
                        email: "Почта"
                    },
                    submit: "Отправить",
                    searchLeftManu: "Поиск",
                    noProjectsInList: "У вас нет проектов",
                    sort: "Сортировка",
                    myTasks: "Мои задачи",
                    allProjects: "Главная",
                    mainPage: "Все команды и проекты",
                    projectsCount_zero: "нет проектов",
                    projectsCount_one: "{{count}} проект",
                    projectsCount_two: "{{count}} проекта",
                    projectsCount_few: "{{count}} проекта",
                    projectsCount_many: "{{count}} проектов",
                    search:"Поиск по проектам и командам",
                    notifications: "Уведомления",
                    techHelp: "Тех.поддержка",
                    createNewProject: "Новый",
                    projectsList: "Проекты",
                    members: "Участники",
                    tasks: "Задачи",
                    resolvedTasks: "Завершенные задачи",
                    userSearch: {
                        AutoCompletePlaceholder: "Поиск пользователя",
                        choiceUsers: "Найди пользователя по имени или имейлу",
                        choiceUsersAndUnions: "Найди пользователя по имени или имейлу или найти команду по названию"
                    },
                    addUsersToProject: "Пригласить",
                    aMemberInProjectOfUser: "Пользователь ",
                    aMemberInProjectOfUsersUnion: "Команда ",
                    project: {
                        transfer: "Передать проект",
                        filesInProject: "Количество файлов в проекте",
                        userNotInProject: "Не участвует в проекте. Но он является участником команды и может быть назначен на задачу",
                        userNotInProjectTitle: "Не в проекте",
                        transferTooltip: "Передача проекта другому пользователю или команде",
                        transferWaiting: "Проект передаётся: ",
                        transferAcceptReject: "передаёт проект",
                        transferAcceptRejectInUsersUnion: "в команду",
                        transferAcceptRejectInPersonal: "в ваши личные проекты",
                        transferRejectTitle: "Не принимать проект?",
                        transferRejectBody: "",
                        createWindowTitle: "Новый проект",
                        leave: "Покинуть",
                        leaveTooltip: "Покинуть этот проект",
                        archivingTooltip:"Отправить проект в архив",
                        leaveConfirmTitle: "Подтвердите.",
                        leaveConfirmDescription: "Покинуть этот проект?",
                        leavedSuccessfully: "Вы покинули проект.",
                        archiving:"В архив",
                        archiveTooltip:"Отправить проект в архив. Он перестанет отображаться в списке проектов. Также освободятся слоты. Но проект будет доступен только для просмотра",
                        unArchiving:"Вернуть из архива",
                        archived:"Проект в архиве",
                        activeProjects:"Активные",
                        archivedProjects:"В архиве",
                        showArchivedProjects:"Показать проекты в архиве",
                        archivingConfirmDescription:"Отправить проект в архив",
                        unArchivingConfirmDescription:"Вернуть из архива этот проект?",
                        joinYourselfToProject: "Присоединиться",
                        descriptionPlaceholder: "Для удобства добавьте описание",
                        titlePlaceholder: "Нужно дать название проекту",
                        title: "Название",
                        dates: "Даты проекта",
                        description: "Описание",
                        isPublic: "Публичный",
                        cover: "Обложка",
                        addCover: "выбрать",
                        create: "Создать проект",
                        noMembers: "",
                        tasks: "Задачи",
                        files: "Файлы",
                        members: "Участники",
                        settings: "Настройки",
                        AddToFavorite: "Добавить в избранные",
                        RemoveFromFavorite: "Убрать из избранных",
                        invitesList: "Приглашения",
                        save: "Сохранить",
                        acceptInvite: "Принять",
                        declineInvite: "Отклонить",
                        status: {
                            new: 'Новый',
                            active: 'В работе',
                            finished: 'Готовый',
                            frozen: 'Отложенный'
                        },
                    },
                    countInvitesUnionMembers:"Максимум участников от команды: {{count}}",
                    projectMembers: {
                        SureRemove: "Удалить участника проекта?"
                    },
                    yes: "Да",
                    no: "Нет",
                    decline: "Отклонить",
                    remove: "Удалить",
                    invitedByEmail: "Приглашение отправлено на email",
                    really: "Действительно?",
                    beCaution: "Будьте очень внимательны!",
                    inviteSureRemove: "Отменить приглашение?",
                    otherUsersProjects: "Персональные проекты",
                    otherUsersUnionsProjects: "Проекты других команд",
                    personalProjects: "Мои проекты",
                    favoriteProjects: "Избранные проекты",
                    usersUnion: {
                        leave: "Покинуть",
                        delete: "Удалить команду",
                        deletedConfirmation: "Команда будет удалена без возможности восстановления.",
                        deletedSuccessfully: "Команда удалена",
                        leaveTooltip: "Покинуть эту команду",
                        leaveConfirmTitle: "Подтвердите.",
                        leaveConfirmDescription: "Покинуть эту команду?",
                        leavedSuccessfully: "Вы покинули команду.",
                        create: "Создать команду",
                        newInvite: "приглашает в команду",
                        createWindowTitle: "Новая команда",
                        title: "Название",
                        titlePlaceholder: "Обязательно укажите название команды",
                        name: "Короткое имя",
                        namePlaceholder: "Имя команды для поиска",
                        onlyNumbersAndSymbols: "Только буквы латинского алфавита и цифры. Первый символ - буква. Минимум 6 символов.",
                        oneHundredMax: "Максимум 100 символов.",
                        nameIsBusySelectAnother: "Имя уже занято. Пожалуйста, выберите другое",
                        description: "Описание",
                        descriptionPlaceholder: "Для удобства добавьте описание",
                        projects: "Проекты",
                        tasks: "Задачи",
                        files: "Файлы",
                        members: "Участники",
                        settings: "Настройки",
                        team: "команда",
                        teams: "Команды",
                        projectInvites: "Приглашения",
                        projectInvitesManage: "Управление участниками проекта от команды",
                        projectsInvitesLink: {
                            key_one: "Одно приглашение",
                            key_few: "{{count}} приглашения",
                            key_many: "{{count}} приглашений",
                            key_other: "{{count}} приглашений"
                        },
                        SureRemoveMember: "Удалить участника команды?",
                        paymentAccount:"Владелец",
                        countInvitesMembers:"Вы можете пригласить {{count}} участников",
                        processProjectInvite: {
                            title: "Выберите участников команды для участия в проекте",
                            searchPlaceholder: "Поиск...",
                            itemUnit: "участник",
                            remove: "remove",
                            selectAll: "Отметить всех",
                            itemsUnit: "участников",
                            selectCurrent: "Отметить текущего",
                            selectInvert: "Инвертировать отметки",
                            removeAll: "Исключить всех",
                            removeCurrent: "Исключить текущего",
                            maxMembers:"Вы можете пригласить {{count}} участников.",
                            editMembersCount:'Укажите, сколько участников этой команды вы хотите пригласить в проект:',
                            notOwnerMessage:"Недостаточно слотов. Обратись к владельцу проекта.",
                            addSlots:"Добавить слотов"
                        }
                    },
                    task: {
                        columns: {
                            todo: "Сделать",
                            wip: "В работе",
                            done: "Готово",
                        },
                        datesSelector: {
                            placeholder: "Запланировать",
                            singleDay: "Один день",
                            fewDays: "Несколько дней",
                            schedule: "Запланировать",
                        },
                        downloadFile: "Скачать файл",
                        childTasks: "Чек-лист",
                        addChildTask: "Добавить подзадачу",
                        create: "Создать задачу",
                        addTask: "Добавить задачу",
                        save: "Сохранить задачу",
                        description: "Описание",
                        title: "Заголовок",
                        titleRequired: "Нужно дать название задаче",
                        importance: "Важность задачи",
                        oneDay: "Дата",
                        startDate: "Начало",
                        endDate: "Дедлайн",
                        startTime: "С",
                        endTime: "До",
                        resolve: "Завершить",
                        resolved: "Завершена",
                        createWindowTitle: "Новая задача",
                        editWindowTitle: "Изменение задачи",
                        editMembers: "Добавить",
                        hideEditMembers: " ",
                        responsible: "Ответственный",
                        removeMember: "Убрать участника",
                        members: "Участники",
                        noMembers: "Добавить участников",
                        files: "Файлы",
                        filesConnectDisk: "Файлы: подключите папку ЯДиска к проекту чтобы работать с файлами",
                        checkListPrompt: "Чек-лист: сохраните задачу, чтобы добавить чек-лист",
                        responsiblePlaceholder: "Выберите из участников проекта",
                        membersPlaceholder: "Выберите из участников проекта",
                        tasksAsList: "Список",
                        tasksAsListTooltip: "Показать список задач в виде списка",
                        tasksAsBoard: "Доска",
                        tasksAsBoardTooltip: "Показать список задач в виде доски",
                        includeResolved: "Выполненные",
                        iAmResponsible: "Мои",
                        allTasks: "Все",
                        removeTask: "Удалить задачу",
                        confirmRemoveTask: "Удалить задачу?",
                        confirmRemoveTaskDescription: "Удалённую задачу нельзя будет восстановить",
                        resolvedTrue: "Выполненные",
                        resolvedFalse: "Выполненные",
                        currentTask: "Близок дедлайн",
                        deadlineOverdue: "Дедлайн остался за бортом",
                        plannedDate: "Задача запланирована",
                        tasksViewFilterByTags: "Фильтр по меткам",
                        search:"Поиск по задачам, ответственным, проектам, командам",
                        sortFields: {
                            title: "Название",
                            responsible: "Ответственный",
                            column: "Статус",
                            deadline: "Дедлайн",
                            importance: "Важность",
                            position: "Положение в колонке",
                        },
                        displayType:{
                            list:"Список",
                            board:"Доска",
                            calendar:"Календарь"
                        },
                        status: {
                            todo: "Сделать",
                            wip: "В работе",
                            done: "Готово"
                        }
                    },
                    events: {
                        view: "Вид",
                        displayAll: "Все действия",
                        displayAllTooltip: "Все действия",
                        displayMessages: "Комментарии",
                        displayMessagesTooltip: "Комментарии",
                        displayEvents: "История",
                        displayEventsTooltip: "История",
                        addComment: "Комментарий",
                        showHistory: "Показать историю",
                        hideHistory: "Скрыть историю",
                        you: 'Вы',
                        user: 'Пользователь',
                        taskChanged: {
                            you: {
                                created: "создали задачу",
                                addTag: "добавили тег {{tags, list}}",
                                removeTag: "удалили тег {{tags, list}}",
                                addFile: "добавили файл {{files, list}}",
                                removeFile: "удалили файл {{files, list}}",
                                addMember: "добавили испольнителя {{members, list}}",
                                removeMember: "удалили исполнителя {{members, list}}",
                                descriptionChange: "изменили описание {{value, list}}",
                                endDateChange: "изменили endDateChange {{value, list}}",
                                endTimeChange: "изменили endTimeChange {{value, list}}",
                                fieldChange: "изменили fieldChange {{value, list}} {{additional, list}}",
                                importanceChange: "Изменили importanceChange {{value, list}}",
                                resolvedChange: "изменили resolvedChange {{value, list}}",
                                startDateChange: "изменили startDateChange {{value, list}}",
                                startTimeChange: "изменили startTimeChange {{value, list}}",
                                titleChange: "изменили заголовок {{value, list}}",
                            },
                            user: {
                                created: "создал задачу",
                                addTag: "добавил тег {{tags, list}}",
                                removeTag: "удалил тег {{tags, list}}",
                                addFile: "добавил файл {{files, list}}",
                                removeFile: "удалил файл {{files, list}}",
                                addMember: "добавил испольнителя {{members, list}}",
                                removeMember: "удалил исполнителя {{members, list}}",
                                descriptionChange: "изменил описание {{value, list}}",
                                endDateChange: "изменил endDateChange {{value, list}}",
                                endTimeChange: "изменил endTimeChange {{value, list}}",
                                fieldChange: "изменил fieldChange {{value, list}} {{additional, list}}",
                                importanceChange: "Изменил importanceChange {{value, list}}",
                                resolvedChange: "изменил resolvedChange {{value, list}}",
                                startDateChange: "изменил startDateChange {{value, list}}",
                                startTimeChange: "изменил startTimeChange {{value, list}}",
                                titleChange: "изменил заголовок {{value, list}}",
                            }
                        }
                    },
                    accessLevel: {
                        admin: "Админ",
                        member: "Участник",
                    },
                    projectInvites: {
                        declineInviteTitle: "Приглашение в проект",
                        declineInviteBody: "Отклонить приглашение?",
                    },
                    usersUnionInvites: {
                        declineInviteTitle: "Приглашение в команду",
                        declineInviteBody: "Отклонить приглашение?",
                    },
                    calendar: {
                        today: "Сегодня"
                    },
                    file: {
                        noConnectedStorage: "Для работы с файлами нужно подключить Яндекс.Диск и привязать папку",
                        connectYandexDisk: "Подключить Я.Диск",
                        attachButton: "Прикрепить",
                        projectAttachFolderBtn: "Прикрепить папку",
                        usersUnionAttachFolderBtn: "Прикрепить папку",
                        projectAttachFolder: "Прикрепить папку к проекту",
                        usersUnionAttachFolder: "Прикрепить папку к команде",
                        attachFolderHeader: "Прикрепить папку",
                        attachedFolders: "Прикреплённая папка",
                        adminHasToAttachFolder: "Админ должен прикрепить папку, чтобы работать с файлами",
                        attach: "прикрепить",
                        detach: "открепить",
                        filesInTasks: "Файлы в задачах"
                    },
                    tags: {
                        add: "Новая метка",
                        projectEditorTitle: "Управление метками проекта",
                        usersUnionEditorTitle: "Управление метками команды",
                        personalEditorTitle: "Управление личными метками",
                        removeConfirmTitle: "Удалить метку?",
                        taggerPlaceholder: "Добавить метки",
                        saveNewTag: "Сохранить новую метку",
                        tagDelitionErrorMessage: "Не удалось удалить метку"
                    },
                    profile: {
                        userName: "Имя пользователя",
                        fullName: "Полное имя",
                        email: "Эл. почта",
                        aboutMe: "Обо мне",
                        edit: "Редактировать",
                        changePassword: "Сменить пароль",
                        save: "Сохранить",
                        newPassword: "Новый пароль",
                        repeatPassword: "Подтвердить пароль",
                        shortInfo: "Краткая подпись",
                        noFullName: "Пользователь еще не добавил полное имя"
                    },
                    youCantEditYourselfInProjectMembers: "Вы не можете изменить или удалить себя из проекта. Это может сделать другой админ",
                    youCantEditYourselfInUsersUnionMembers: "Вы не можете изменить или удалить себя из команды. Это может сделать другой админ",
                    noProjects: "Нет проектов",
                    termsOfUse: "Условия использования",
                    contacts: "Контакты",
                    tariffsTable:{
                        title:"Тарифы",
                        personalProjectTitle:"Личные проекты",
                        usersUnionTitle:"Команды",
                        freeCost:"Бесплатно",
                        period:"мес",
                        numberOfPersonalProjects:{
                            title:"Количество личных проектов",
                            number:"∞"
                        },
                        personalProjectMembersFreeCount:"Бесплатных приглашений и участников",
                        personalProjectAdditionalMemberAdditionalCost:"Дополнительное приглашение",
                        personalProjectNumberBeOnTeam:{
                            title:"Состою в проектах",
                            number:"∞"
                        },
                        usersUnionFreeCount:"Бесплатные",
                        usersUnionAdditionalCost:"Дополнительная команда",
                        usersUnionMembersFreeCount:"Бесплатные участники и приглашения в командах",
                        usersUnionMembersAdditionalCost:"Дополнительный участник команды",
                        usersUnionProjectMemberFreeCount:"Бесплатные участники в проектах команд",
                        usersUnionProjectMemberAdditionalCost:"Дополнительный участник проекта команд",
                        usersUnionCanInvolvedInProjects:{
                            title:"Участие команды в проектах",
                            number:"∞"
                        },
                        button:"Выбрать"
                    },
                    cookieConsent: {
                        mainText: "Мы используем файлы cookie, чтобы обеспечивать правильную работу нашего веб-сайта и анализировать сетевой трафик.",
                        linkText: "Согласие на обработку и политика в отношении персональных данных",
                        acceptButton: "Согласен"
                    },
                    paymentAccount:{
                        currentTariff:"Текущий тариф",
                        history:"Счета",
                        change:"Сменить",
                        changeTariff:"Сменить тариф",
                        period:"Ежемесячно:",
                        payNaw:"Сейчас оплатить:",
                        paymentDate:"Следующий платеж:",
                        freeCost:"В тарифе",
                        paid:"Дополнительные",
                        personalProjectAdditionalMember:"Участники в личные проекты",
                        usersUnion:"Команды",
                        usersUnionMembers:"Участники команд'",
                        usersUnionProjectMember:"Участники командных проектов",
                        remove:"Удалить",
                        add:"Добавить",
                        total:"Всего",
                        used:"Используется",
                        addSlots:"Приобрести дополнительные слоты",
                        return:"Вернутся",
                        pay:"Оплатить",
                        paymentSuccessful:"Платеж прошел успешно",
                        paymentFail:"Произошла ошибка",
                        paymentFailHelp:"Перейдите по ссылке, что бы мы могли вам помочь",
                        projectIsBlocked:"Проект заблокирован",
                        unionIsBlocked:"Команда заблокирована",
                        wasPaymentDate:"Дата платежа",
                        currency:"Валюта",
                        amount:"Сумма",
                        receiptOf:"Счет ",
                        promisePay:"Не удалось списать оплату. Пожалуйста оплатите до {{data}}",
                        isBlocked:"Ваши проекты и команды заблокированы. Пожалуйста, оплатите счет. ",
                        receiptSuccess: "Оплачен",
                        receiptPending: "Обрабатывается",
                        receiptCanceled: "Отменён",
                    },
                    errorModal:{
                        errorTariffUnions:"На тарифе Хобби вы можете создавать не более одной команды."
                    },
                    editDataMassage:"Информация обновлена"

                }
            }
        }
    })

export const changeLanguage = (lang: string) => {
    lang = "ru" // TODO: изменить, когда будут еще языки
    i18next.changeLanguage(lang)
    dayjs.locale(lang);
    selectedLanguage(languages.find(v=>v.key === lang) || ru)
}

export class LanguageSettings {
    key!: string;
    title!: string
    locale!: Locale
}


export const en: LanguageSettings = {
    key: "en",
    title: "English",
    locale: antEn
}

export const ru: LanguageSettings = {
    key: "ru",
    title: "Русский",
    locale: antRu
}

export const it: LanguageSettings = {
    key: "it",
    title: "Italiano",
    locale: antIt
}

export const selectedLanguage: ReactiveVar<LanguageSettings> = makeVar<LanguageSettings>(ru);

export const languages = [ru, en, it]