import React from "react";
import {CustomUserAvatar} from "./AntCastomComponents/CustomLeftMenu/CustomUserAvatar/CustomUserAvatar";
import {User} from "../generated-types";
import TooltipButton from "./TooltipButton";
import {IconNotification} from "./Icon/IconNotification";
import {FooterLogo} from "./AntCastomComponents/CustomLeftMenu/FoterLogo/FooterLogo";
import {theme} from "antd";
import {useNavigate} from "react-router-dom";
import {useReactiveVar} from "@apollo/client";
import {authState} from "../routes/Auth/authContext";
import {useTranslation} from "react-i18next";
import {IconMenu} from "./Icon/IconMenu";
import styled from "styled-components";
import {GlobalToken} from "antd/es/theme/interface";
import {Header} from "antd/es/layout/layout";
import {isMobile} from "react-device-detect";
import {IconClose} from "./Icon/IconClose";
import {IconRock} from "./Icon/IconRock";
import {Icon} from "./Icon/Icon";

export interface TopMenuProps {
    collapsed: boolean
    setCollapsed: (v: boolean) => void
}

const H = styled(Header)<{ token: GlobalToken }>`
    display: flex;
    background-color: ${({token}) => token.colors.ui.bgDark};
    align-items: center;
    flex-direction: row-reverse;
    height: 56px;
    padding: 0 10px;

    .right-holder {
        margin-right: auto;
        display: flex;
        flex-direction: row-reverse;
        align-items: center;
        gap: 8px;
    }
`

export const TopMenu: React.FC<TopMenuProps> = ({setCollapsed, collapsed}) => {
    const {t} = useTranslation();
    const {token} = theme.useToken();
    const navigate = useNavigate();
    const authInfo = useReactiveVar(authState);

    const authorized = authInfo.user != undefined;

    return (<H token={token}>
        {authorized && <CustomUserAvatar user={authInfo.user as User}/>}
        {authorized && <TooltipButton
			    icon={<Icon size={"24"} color={token.colors.ui.white} icon={<IconNotification/>}/>}
				                              type={"text"}
				                              onClick={() => {
                                          navigate("/notifications")
                                      }}
				                              tooltipProps={{title: t('notifications')}}>
				</TooltipButton>}
        {authorized && <TooltipButton icon={<Icon size={"24"} icon={<IconRock/>}/>}
		                                  type={"text"}
				                              onClick={() => {
                                          window.open("https://t.me/+ZHao_pYdqQRmYTdi")
                                      }}
				                              tooltipProps={{title: t('techHelp')}}>
				</TooltipButton>}
        <div className="right-holder">
            <FooterLogo/>
            {isMobile && <TooltipButton
							style={{marginRight: "auto"}}
														icon={<Icon size={"24"} color={token.colors.ui.white}
                                        icon={collapsed ? <IconMenu/> : <IconClose/>}/>}
							type={"text"}
							onClick={() => {
                  setCollapsed(!collapsed)
              }}
						>
						</TooltipButton>}
        </div>
    </H>)
}