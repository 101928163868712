import React, {ReactElement, useRef, useState} from "react";
import {Input, theme, Tooltip} from "antd";
import {SearchOutlined, WalletOutlined,} from "@ant-design/icons";
import {useTranslation} from "react-i18next";
import {Link, useNavigate} from "react-router-dom";
import {useReactiveVar} from "@apollo/client";
import {Project, User, UsersUnion} from "../../../generated-types";
import {allUsersUnions} from "../../../subscriptions/allUsersUnions";
import {allProjects} from "../../../subscriptions/allProjects";
import {CustomMenu, LeftSider, TitleContainer, TitleText} from "./LeftMenu.styled";
import {LeftMenuButtons} from "./LeftMenuButtons/LeftMenuButtons";
import {LeftMenuCollapseButton} from "../LeftMenuCollapseButton";
import {isMobile} from "react-device-detect";
import styled from "styled-components";
import type {GlobalToken} from "antd/es/theme/interface";
import PointDivider from "../../PointDivider";
import UsersUnionsAvatar from "../../UsersUnion/UsersUnionsAvatar";
import UserAvatar from "../../User/UserAvatar";
import {IconFavourites} from "../../Icon/IconFavourites";
import {IconProjects} from "../../Icon/IconProjects";
import {IconTeam} from "../../Icon/IconTeam";
import {IconMan} from "../../Icon/IconMan";
import {IconArrowDown} from "../../Icon/IconArrowDown";
import {IconArrowUp} from "../../Icon/IconArrowUp";
import {Icon} from "../../Icon/Icon";


export interface LeftMenuProps {
    contentMargin: number,
    setContentMargin: (number: number) => void
    collapsed: boolean
    setCollapsed: (v: boolean) => void
}

export const menuItems: any = {
    personalProject: '/my',
    uuProjects: '/uu',
    otherProjects: '/otherp',
}


export const LeftMenuSearch = styled(Input)<{ token: GlobalToken }>`
    margin: 12px;
    width: 90%;
    border-radius: 8px;
    background-color: ${(p) => p.token.colors.ui.bgDark};

    color: ${(p) => p.token.colors.font.white};

    ::placeholder {
        color: ${(p) => p.token.colors.font.white};
        opacity: 0.5; /* Firefox */
    }

    :focus {
        color: red;
    }
`

interface ItemMenuElementProps {
    project: Project,
    menuCollapsed: boolean,
    onItemClick: (link: string) => void
}

export const ItemMenuElement: React.FC<ItemMenuElementProps> = ({menuCollapsed, project, onItemClick}) => {
    const to = `/project/${project.id}`;
    // TODO: иконки сохранять и цвет указывать в настройках проекта (p.icon, p.iconColor)
    const projectIcon = <WalletOutlined
        style={{backgroundColor: 'red', padding: 4, borderRadius: 3, width: 24, height: 24, alignSelf: "center"}}
                                        rev={undefined}/>;

    const className = "project-title" + (currentMenuIsSelected(to) ? " selected" : "");

    if (menuCollapsed)
        return <Tooltip title={project.title} placement={"right"} className={className}>
            <Link onClick={() => {
                onItemClick(to)
            }} to={to}>{projectIcon}</Link>
        </Tooltip>;

    return <Tooltip title={project.title} placement={"right"} className={className}>
        {projectIcon}
        <Link onClick={_ => onItemClick(to)} to={to}>{project.title}</Link>
    </Tooltip>
}

interface GroupMenuElementProps {
    menuCollapsed: boolean
    e: MenuEntity
    onItemClick: (link: string) => void
}

export const GroupMenuElement: React.FC<GroupMenuElementProps> = ({menuCollapsed, e, onItemClick}) => {
    const {token} = theme.useToken();
    const {children, entity} = e;
    const [expanded, setExpanded] = useState(false)

    let link: string = `/${entity.__typename}/${entity.id}`;
    let title: string = "";
    let icon: ReactElement | null = null;
    let k = (entity as any).__typename + "_" + (entity as any).id;

    if (e.entity?.__typename == "UsersUnion") {
        title = (entity as UsersUnion)?.title
        icon = <UsersUnionsAvatar uu={entity as UsersUnion}/>;
    } else if (e.entity?.__typename == "User") {
        title = (entity as User)?.username
        icon = <UserAvatar user={entity as User}/>;
    } else if (e.entity?.__typename == "Project") {
        return <ItemMenuElement key={k} onItemClick={onItemClick}
                                menuCollapsed={menuCollapsed}
                                project={entity as Project}/>
    }

    // if (children == undefined || children.length == 0)
    //     return <NoProjectsElement menuCollapsed={menuCollapsed} />;

    const arrow = <div style={{
        backgroundColor: menuCollapsed ? token.colors.ui.bgDark : undefined, width: 20, height: 20, borderRadius: 4,
        color: token.colors.font.white
    }}
                       onClick={(e) => {
                           setExpanded(!expanded)
                           e.preventDefault()
                           e.stopPropagation()
                       }}>
        {
            expanded
                ? <IconArrowUp style={{width: 16, height: 16, margin: 3}} color={token.colors.font.white}/>
                : <IconArrowDown style={{width: 16, height: 16, margin: 3}} color={token.colors.font.white}/>
        }
    </div>;

    const titletxt = <div className={"title" + (currentMenuIsSelected(link) ? " selected" : "")} onClick={(e) => {
        onItemClick(link)
        e.preventDefault()
        e.stopPropagation()
    }}>{icon}<TitleText collapsed={menuCollapsed ? "true" : undefined}>{title}
        {children.length > 0 && <PointDivider value={children.length}/>}
        {!menuCollapsed && children.length > 0 && arrow}
        {/*{children.length > 0 && <Button type={"link"} style={{width: 24, height: 24}}><IconArrowDown /></Button>}*/}
    </TitleText></div>;

    return <TitleContainer className={"title-container"}>
        {/*{menuCollapsed ? <Tooltip title={title}>{titletxt}</Tooltip> : titletxt}*/}
        {titletxt}

        {menuCollapsed && children.length > 0 &&
					<div className={"title"} onClick={(e) => {
          }}>{arrow}</div>
        }
        <div className="items-holder">
            {
                expanded && children
                    .map(entity => {
                        return (<ItemMenuElement key={link} menuCollapsed={menuCollapsed} onItemClick={onItemClick}
                                                 project={entity.entity as Project}/>)
                    })
            }
        </div>
    </TitleContainer>;
}

interface TopMenuElementProps {
    menuCollapsed: boolean
    icon: ReactElement
    title: string
    link: string
    children: MenuEntity[]
    onItemClick: (link: string) => void
}

export const TopMenuElement: React.FC<TopMenuElementProps> = ({
                                                                  menuCollapsed,
                                                                  icon,
                                                                  link,
                                                                  title,
                                                                  children,
                                                                  onItemClick
                                                              }) => {
    const titletext = <div className={"title" + (currentMenuIsSelected(link) ? " selected" : "")} onClick={(e) => {
        onItemClick(link);
        e.preventDefault()
        e.stopPropagation()
    }}>{icon}<TitleText collapsed={menuCollapsed ? "true" : undefined}>{title}
        {children.length > 0 && <PointDivider value={children.length}/>}
    </TitleText></div>;

    return <TitleContainer className={"top-title-container"}>
        <div className={"divider"}></div>
        {menuCollapsed ? <Tooltip title={title} placement={"right"}>{titletext}</Tooltip> : titletext}

        <div className="items-holder">
            {
                children.map(entity => {
                    const k = (entity.entity as any).__typename + "_" + (entity.entity as any).id;
                    return <GroupMenuElement key={k} menuCollapsed={menuCollapsed} e={entity}
                                             onItemClick={onItemClick}/>
                })
            }
        </div>
    </TitleContainer>;
}


export const NoProjectsElement: React.FC<{ menuCollapsed: boolean }> = ({menuCollapsed}) => {
    const {token} = theme.useToken();
    const {t} = useTranslation();

    return <span style={{color: token.colors.font.white}}>{t('noProjects')}</span>;
}

interface MenuEntity {
    entity: Project | UsersUnion | User,
    children: MenuEntity[],
}

export const currentMenuIsSelected = (link: string) => {
    let res = false;
    if (!link) res = false;
    else if (link == "/" && window.location.pathname == "") res = true;
    else res = window.location.pathname.endsWith(link);
    console.log(window.location.pathname, link, res);

    return res;
}

export const LeftMenu: React.FC<LeftMenuProps> = ({collapsed, setCollapsed}) => {
    const {t} = useTranslation();
    const {token} = theme.useToken();
    const navigate = useNavigate();

    const menuRef = useRef<any>(null)

    const alwaysOpened = [t("favoriteProjects"), t("otherUsersProjects"), t("usersUnion.teams"), t("personalProjects")]

    // const [openedKeys, setOpenedKeys] = useState<string[] | undefined>(getLeftMenuOpenKeys(alwaysOpened));
    // const [selectedKey, setSelectedKey] = useState<string>(""); // TODO: get from location

    const [menuFilter, setMenuFilter] = useState<string>("");

    const [isMd, setMd] = useState<boolean>(false) //изменяется на true - false при изменении ширины экрана.

    const allUnions = useReactiveVar(allUsersUnions);
    const allPrj = useReactiveVar(allProjects);

    const leftMenuProjectsFilter = (p: Project): boolean => p.title.indexOf(menuFilter) > -1;

    const onlyFavoriteFilter = (p: Project): boolean => (p.UserMarkers.isFavorite && leftMenuProjectsFilter(p));

    const projectSorter = (p1: Project, p2: Project): number => p1.usersUnionAsOwner?.title + "-" + p1.title > p1.usersUnionAsOwner?.title + "-" + p2.title ? 1 : -1;

    // TODO: это надо сделать для мобилок обязательно
    // // Указание выбранного пункта меню в соответствии с адресом страницы
    // useEffect(() => {
    //     const selectedKeyFromPath = getActiveLeftMenuKey(leftItems, window.location.pathname)
    //     if (selectedKeyFromPath !== selectedKey)
    //         setSelectedKey(selectedKeyFromPath)
    // }, [selectedKey]);

    // const allKeys = leftItems.map(v => v.key);
    // if (!openedKeys)
    //     setOpenedKeys(allKeys);
    //
    //
    // const openedKeysResult = useMemo(() => {
    //     let res = openedKeys ?? [];
    //     // Чтобы эти элементы меню нельзя было свернуть
    //     if (!collapsed)
    //         alwaysOpened.find(vv => {
    //             if (res.indexOf(vv) < 0) res.push(vv)
    //         })
    //     return res;
    // }, [openedKeys, collapsed])

    const showCollapseButton = !isMobile;

    const favItems = allPrj.projects.filter(onlyFavoriteFilter).sort(projectSorter).map(entity => ({
        entity,
        children: []
    } as MenuEntity));

    //TODO: проверить правильность кода в этом блоке
    const personalItems = allPrj.myProjects().filter(leftMenuProjectsFilter).sort(projectSorter).map(entity => ({
        entity,
        children: []
    } as MenuEntity));

    const uuItems = allUnions.unions.map(entity => ({
        entity,
        children: allPrj.projects
            .filter(p => p.usersUnionAsOwner?.id == entity.id).filter(leftMenuProjectsFilter).sort(projectSorter).map(entity => ({
                entity,
                children: []
            } as MenuEntity))
    } as MenuEntity))

    const uItems = allPrj.otherUsers().map(entity => ({
        entity,
        children: allPrj.projects
            .filter(p => p.usersUnionAsOwner?.id == entity.id).filter(leftMenuProjectsFilter).sort(projectSorter).map(entity => ({
                entity,
                children: []
            } as MenuEntity))
    } as MenuEntity))

    const onItemClick = (link: string) => {
        if (!link) return;

        navigate(link)
        if (isMobile)
            setCollapsed(true)
    }

    return <LeftSider
        token={token}
        breakpoint="md"
        width={isMd ? "100%" : 300}
        collapsedWidth={isMd ? "0" : "88"}
        trigger={null}
        collapsed={collapsed}
        onCollapse={(value) => setCollapsed(value)}
        onBreakpoint={(broken) => setMd(broken)}
    >
        {showCollapseButton && <LeftMenuCollapseButton collapsed={collapsed} isMd={isMd} setCollapsed={setCollapsed}/>}

        <div className="left-menu">
            <div style={{width: '100%', height: '100%', overflow: 'hidden'}}>
            {!collapsed && <LeftMenuSearch token={token} size="middle" placeholder={t('searchLeftManu')}
                            onChange={(e) => {
                                setMenuFilter(e.currentTarget.value)
                            }}

                                           prefix={<SearchOutlined/>}/>}

                <LeftMenuButtons collapsed={collapsed} onClick={onItemClick}/>

                <CustomMenu token={token} collapsed={collapsed.toString()} mobile={isMobile.toString()} ref={menuRef}>
                <TopMenuElement
                    menuCollapsed={collapsed}
                    icon={<Icon size={"24"} icon={<IconFavourites/>}/>}
                    title={t("favoriteProjects")}
                    link={""}
                    children={favItems}
                    onItemClick={onItemClick}
                />

                <TopMenuElement
                    menuCollapsed={collapsed}
                    icon={<Icon size={"24"} icon={<IconProjects/>}/>}
                    title={t("personalProjects")}
                    link={menuItems.personalProject}
                    children={personalItems}
                    onItemClick={onItemClick}
                />

                <TopMenuElement
                    menuCollapsed={collapsed}
                    icon={<Icon size={"24"} icon={<IconTeam/>}/>}
                    title={t("usersUnion.teams")}
                    link={menuItems.uuProjects}
                    children={uuItems}
                    onItemClick={onItemClick}
                />

                <TopMenuElement
                    menuCollapsed={collapsed}
                    icon={<Icon size={"24"} icon={<IconMan/>}/>}
                    title={t("otherUsersProjects")}
                    link={menuItems.otherProjects}
                    children={uItems}
                    onItemClick={onItemClick}
                />
            </CustomMenu>
            </div>
        </div>

    </LeftSider>
}