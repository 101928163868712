import React, {useMemo, useState} from "react";
import {Button, theme} from "antd";
import TweenOne from "rc-tween-one";
import styled from "styled-components";
import {isMobile} from "react-device-detect";
import {GlobalToken} from "antd/es/theme/interface";
import {IconArrowLeft} from "../Icon/IconArrowLeft";
import {IconArrowRight} from "../Icon/IconArrowRight";

export const CollapsedButton = styled(TweenOne)`
  z-index: 100;
  
  position: absolute;
`

export const Btn = styled(Button)<{ token: GlobalToken }>`
  font-size: 16px;
    width: 24px !important;
    height: 40px !important;
  right: initial;
    background-color: ${({token}) => token.colors.ui.bgLight3};

    &:hover {
        background-color: ${({token}) => token.colors.ui.bgLight3} !important;
    }
  border: 1px solid rgb(187, 187, 187);
  box-shadow: rgb(185, 185, 185) 1px 1px 3px 0;
  border-radius: 7px;
`

interface LeftMenuCollapseButtonProps {
    collapsed: boolean
    isMd: boolean
    setCollapsed: (v: boolean) => void
}


let timer: any;

export const LeftMenuCollapseButton: React.FC<LeftMenuCollapseButtonProps> = ({collapsed, isMd, setCollapsed}) => {

    const [moment, setMoment] = React.useState<number | undefined>(0);
    const [hovered, setHovered] = useState(false)
    const {token} = theme.useToken();

    const left = useMemo(() => {
        if (!isMd) {
            return !collapsed ? '288px' : '76px'
        } else
            return collapsed ? '-16px' : 'initial'
    }, [isMd, collapsed])

    const stop = (timeout: number) => {
        if (timer) clearTimeout(timer)
        timer = setTimeout(() => {
            setMoment(undefined);
            setTimeout(() => {
                setMoment(0);
            });
            setHovered(false)
        }, timeout)
    }

    const start = () => {
        if (!hovered)
            setHovered(true)
    }

    const icon = collapsed
        ? <IconArrowRight color={token.colors.ui.bgDark} width={16} height={16}/>
        : <IconArrowLeft color={token.colors.ui.bgDark} width={16} height={16}/>;

    const btn = <Btn
        type="text"
        icon={icon}
        onClick={() => {
            setCollapsed(!collapsed)
        }}
        token={token}
    />;

    if (isMobile)
        return <div style={{
            left: left,
            zIndex: 100,
            top: 2,
            position: "fixed"
        }}>{btn}</div>;

    return <CollapsedButton
        style={{
            right: 0,
            top: "50%",
            width: "24",
            height: "40",
            transform: "translate(50%, -50%)"
        }}
        animation={{
            x: collapsed ? 7 : -7,
            yoyo: true,
            repeat: 2,
            duration: 1000
        }}
        paused={!hovered}
        moment={moment}
    >
        {btn}
    </CollapsedButton>
}