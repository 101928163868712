import {theme} from 'antd';
import React from 'react';
import styled from "styled-components";

interface PointDividerProps {
    value?: number;
}


const Container = styled.span<{ color: string }>`
    color: $color;
    opacity: 0.5
`

const Point = styled.span`
    padding: 4px;
    font-weight: 900;
`
const Value = styled.span`
`

const PointDivider = (props: PointDividerProps) => {
    const {token: {colors}} = theme.useToken();

    if (!props.value)
        return <></>

    return (
        <Container color={colors.ui.white}><Point>·</Point><Value>{props.value}</Value></Container>
    )
};

export default PointDivider;